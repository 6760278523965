import React, { ReactElement } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { contactUsBannerImg, aboutBannerHeight } from "../models/models"

function ContactUs(): ReactElement {
  return (
    <>
      <Header page={"Contact Us"} />
      <div className="w-full flex flex-col items-center justify-center">
        <div
          className="relative w-full overflow-hidden"
          style={{ height: aboutBannerHeight }}
        >
          <img
            className="inset-0 absolute w-full"
            alt="banner_img"
            src={contactUsBannerImg}
          />
        </div>
        <div className="py-2 px-2 md:py-10 md:px-64 text-justify">
          <p className="text-md md:text-xl text-gray-700">
            We would enjoy hearing from you! Feedback is appreciated.
          </p>
          <div className="mt-10">
            <a
              className="text-blue-500 font-bold text-lg"
              href="mailto: forwardapps@outlook.com"
            >
              forwardapps@outlook.com
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ContactUs
